import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { HeartToggle } from '@moonpig/launchpad-components'
import { spacingPx } from '@moonpig/launchpad-theme'
import { useLocaleText } from '../../ProductTile.locale'

const StyledHeartToggleWrapper = styled.div`
  bottom: ${spacingPx(12)};
  ${s({
    position: 'absolute',
    bottom: 2,
    right: 2,
    zIndex: 2,
  })}
`

type Props = {
  favourite: {
    isSelected?: boolean
    onSelect: () => void
  }
}

export const FavouriteButton: FC<Props> = ({ favourite }) => {
  const localiseText = useLocaleText()
  return (
    <StyledHeartToggleWrapper>
      <HeartToggle
        isToggled={!!favourite.isSelected}
        onToggle={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.preventDefault()
          e.stopPropagation()
          favourite.onSelect()
        }}
        ariaLabel={
          favourite.isSelected
            ? `${localiseText('card_product_tile.remove_from_favourites')}`
            : `${localiseText('card_product_tile.add_to_favourites')}`
        }
      />
    </StyledHeartToggleWrapper>
  )
}
