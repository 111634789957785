import React, { useCallback, useRef, useState, FC } from 'react'
import {
  Box,
  SecondaryButton,
  TertiaryButton,
} from '@moonpig/launchpad-components'
import { formatPrice } from '@moonpig/web-core-utils'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { Region } from '@moonpig/web-core-types'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useLanguage, useStoreId } from '@moonpig/web-core-stores'
import { useExperiment } from '@moonpig/web-core-experiments'
import { DepartmentsEnum } from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { CardProductTile } from './CardProductTile'
import { NonCardProductTile } from './NonCardProductTile'
import {
  ProductItemEventEnum,
  ProductList,
  ProductClickedEvent,
  AddedToBasketEvent,
} from '../../types'
import { InternalUserPanel } from './InternalUserPanel'
import { ProductTileProduct } from './ProductTileProduct'
import { useLocaleText } from './ProductTile.locale'
import { getProductImageDimensions } from '../../utils/getProductImageDimensions'
import { isCardProduct } from '../../utils/isCardProduct'
import { isPortraitCard } from '../../utils/isPortraitCard'
import { createProductItemGAEvent } from '../../analytics'
import { transformPill } from '../../utils/transformPill'
import { useDigitalGiftingCardOptions } from '../../utils/useDigitalGiftingCardOptions/useDigitalGiftingCardOptions'
import { useProductModal } from '../../contexts/productModal/Context'
import { isDigitalGift } from '../../utils/isDigitalGift/isDigitalGift'
import { CardProductTileVariant } from './CardProductTileVariant/CardProductTileVariant'
import { CardProductTileGalleryVariant } from './CardProductTileGalleryVariant'
import { getCardFormat } from '../../utils/getCardFormat/getCardFormat'

const IMAGE_URL_SUFFIX = `?w=400`

const StyledSecondaryButton = styled(SecondaryButton)`
  && {
    ${s({
      p: 0,
      mb: { xs: 5, lg: 6 },
      mx: { xs: 5, lg: 6 },
    })}
  }
`

function useCallbackOnce<T>(callback: (arg: T) => void) {
  const [firstInvocation, setFirstInvocation] = useState(true)

  return React.useCallback(
    (arg: T) => {
      if (firstInvocation) {
        callback(arg)
        setFirstInvocation(false)
      }
    },
    [callback, firstInvocation, setFirstInvocation],
  )
}

type ReactMouseEvent = React.MouseEvent<HTMLAnchorElement, MouseEvent>

type Props = {
  product: ProductTileProduct
  trackingData: {
    pageType: string
    metaTitle?: string
    region?: Region
    tile: {
      productIndex: number
      totalNumberOfProducts: number
    }
    productList?: ProductList
  }
  ref?: React.MutableRefObject<null>
  onClick: (clickedEvent: ProductClickedEvent) => void
  onFirstClick?: (clickedEvent: ProductClickedEvent) => void
  onAddToBasket: (addedEvent: AddedToBasketEvent) => void
  isInternalUser?: boolean
  isFavourited: boolean
  handleFavourite: (
    product: ProductTileProduct,
    isFavouriteSelected: boolean,
    index: number,
  ) => Promise<{ removeWithConfirmation: boolean }>
  isGallery?: boolean | undefined
  showProductTabs?: boolean
  hideStickyCta?: boolean
  showOurPick?: boolean
  groupCardProject?: string
  tabbedCarousel?: boolean
  showCardProductTileVariant?: boolean
  onTrackingInfo?: (e: { product: ProductTileProduct }) => {
    label: string
    listName: string
  }
}

export const ProductTile: FC<Props> = ({
  product,
  trackingData,
  onClick,
  onFirstClick,
  onAddToBasket,
  isFavourited,
  handleFavourite,
  isInternalUser = false,
  isGallery,
  showProductTabs = false,
  hideStickyCta = false,
  showOurPick = false,
  groupCardProject,
  tabbedCarousel,
  showCardProductTileVariant = false,
  onTrackingInfo,
}) => {
  const productTileRef = useRef<HTMLAnchorElement>(null)
  const {
    title,
    id: productId,
    masterVariant,
    rating,
    category: { slug: categorySlug },
    slug,
    publishDate,
    isLandscape,
    primaryProductPill,
    clickRankDocumentCount,
  } = product
  const region = useStoreId()
  const productIndex = trackingData.tile.productIndex

  const localiseText = useLocaleText()
  const language = useLanguage()
  const { show } = useProductModal()
  const { getCanSeeCardOptions, seeCardOptionsForProduct } =
    useDigitalGiftingCardOptions()

  const isOutlinedCtaButton =
    useExperiment('gallery-cta-experiment') === 'enabled'

  const { masterImage, productImages, price, fullPrice, discountedPercentage } =
    masterVariant

  const formattedPrice = formatPrice(
    price.centAmount,
    price.fractionDigits,
    price.currencyCode,
    undefined,
    language,
  )

  const formattedFullPrice = formatPrice(
    fullPrice.centAmount,
    fullPrice.fractionDigits,
    fullPrice.currencyCode,
    undefined,
    language,
  )

  const isCard = product.category && isCardProduct(product.category.department)

  const productDetailsURL = `/${region}/${categorySlug}/p/${slug}/${productId.toLowerCase()}/`

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const firstClickCallback = onFirstClick && useCallbackOnce(onFirstClick)

  const getTrackingInfo = () => {
    const { pageType, metaTitle, productList, tile } = trackingData
    const trimmedPageType = pageType.trim()
    const getLabel = (listNameLabel: string) =>
      `${listNameLabel} | ${productIndex + 1}/${tile.totalNumberOfProducts} | ${
        product.id
      }`

    if (!productList) {
      const listName = metaTitle
        ? `${trimmedPageType} | ${metaTitle}`
        : trimmedPageType
      return {
        label: getLabel(listName),
        listName,
      }
    }

    const carouselType = `${tabbedCarousel ? 'tabbed ' : ''}carousel`
    const modulesCount = `${productList.position}/${productList.totalNumberOfLists}`
    const listName = tabbedCarousel
      ? `${trimmedPageType} | ${productList.tabbedModuleTitle} | ${carouselType} | ${modulesCount} | ${productList.title} tab`
      : `${trimmedPageType} | ${productList.title} | ${carouselType} | ${modulesCount}`
    const label = getLabel(listName)
    return {
      label,
      listName,
    }
  }
  const handleClick = useCallback(
    (e: ReactMouseEvent) => {
      e.preventDefault()

      if (isGallery && !isCard) {
        window.open(productDetailsURL, '_self')
      } else {
        show({
          triggerElementRef: productTileRef,
          index: productIndex,
          product,
          groupCardProject,
          configuration: { showProductTabs, showStickyCta: !hideStickyCta },
          onAddToBasket,
        })
      }

      const trackingInfo = onTrackingInfo?.({ product }) || getTrackingInfo()

      trackGAEvent(
        createProductItemGAEvent({
          eventType: ProductItemEventEnum.SELECT_ITEM,
          product,
          index: productIndex,
          variant: product.masterVariant.title,
          ...trackingInfo,
        }),
      )

      onClick({ product, variant: masterVariant, index: productIndex })

      if (firstClickCallback) {
        firstClickCallback({
          product,
          variant: masterVariant,
          index: productIndex,
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onClick, product, masterVariant, productIndex, firstClickCallback],
  )

  const imageDimensions = getProductImageDimensions(
    isCard,
    masterVariant.key,
    isLandscape,
  )

  const CtaButton = isOutlinedCtaButton ? StyledSecondaryButton : TertiaryButton

  const { canSeeCardOptions, digitalGiftCtaToken } = getCanSeeCardOptions(
    product.category.department,
  )

  const seeCardOptionsForDigitalGift = () => {
    const trackingInfo = onTrackingInfo?.({ product }) || getTrackingInfo()

    return seeCardOptionsForProduct({
      productId,
      gaEvent: createProductItemGAEvent({
        eventType: ProductItemEventEnum.SELECT_ITEM,
        product,
        variant: product.masterVariant.title,
        index: productIndex,
        ...trackingInfo,
      }),
    })
  }

  const renderCtaText = (
    productDepartment: DepartmentsEnum,
    children: React.ReactChildren,
  ) => {
    if (isDigitalGift(productDepartment)) {
      return localiseText(digitalGiftCtaToken)
    }

    return isOutlinedCtaButton
      ? localiseText('common.add_to_basket_cta')
      : children
  }

  const ctaClick = () =>
    canSeeCardOptions
      ? seeCardOptionsForDigitalGift()
      : onAddToBasket({
          product,
          index: productIndex || 0,
          productIndex: productIndex || 0,
          variant: product.masterVariant,
          quantity: 1,
          trackPersonaliseEvent: true,
        })

  const label = primaryProductPill ? primaryProductPill.displayLabel : undefined
  const variant = primaryProductPill
    ? transformPill(primaryProductPill).displayVariant
    : undefined

  const frontImage =
    productImages && productImages.images.length > 0
      ? productImages.images[0].medium.jpgUrl
      : masterImage.url
  const hasInsideRight = productImages && productImages.images.length >= 3

  return (
    <>
      {isInternalUser && (
        <InternalUserPanel
          productId={productId}
          publishDate={(publishDate && new Date(publishDate)) || undefined}
          clickRankDocumentCount={clickRankDocumentCount || 0}
        />
      )}
      {isCard &&
        isGallery &&
        (showCardProductTileVariant ? (
          <CardProductTileVariant
            images={{
              front: {
                src: frontImage,
                alt: title,
                dimensions: imageDimensions,
              },
              insideRight: hasInsideRight
                ? {
                    src: productImages.images[2].small.jpgUrl,
                    alt: title,
                    dimensions: imageDimensions,
                  }
                : undefined,
            }}
            onClick={handleClick}
            href={productDetailsURL}
            format={getCardFormat({
              isLandscape: !!isLandscape,
              variantKey: masterVariant.key,
            })}
            key={`product-${productId}`}
            pill={
              label && variant
                ? { label, variant }
                : /* istanbul ignore next */ undefined
            }
            favourite={{
              isSelected: isFavourited,
              onSelect: () =>
                handleFavourite(product, !!isFavourited, productIndex),
            }}
            isSponsored={product.isSponsored}
            ref={productTileRef}
          />
        ) : (
          <CardProductTileGalleryVariant
            href={productDetailsURL}
            image={{
              src: `${masterImage.url}${IMAGE_URL_SUFFIX}`,
              alt: title,
            }}
            onClick={handleClick}
            key={`product-${productId}`}
            ref={productTileRef}
            portraitCardAspectRatio={
              imageDimensions.width / imageDimensions.height
            }
            favourite={{
              isSelected: isFavourited,
              onSelect: () =>
                handleFavourite(product, !!isFavourited, productIndex),
            }}
            label={label}
            variant={variant}
            sponsored={product.isSponsored}
          />
        ))}
      {isCard && !isGallery && (
        <Box width={1}>
          <CardProductTile
            href={productDetailsURL}
            image={{
              src: `${masterImage.url}${IMAGE_URL_SUFFIX}`,
              alt: title,
            }}
            onClick={handleClick}
            key={`product-${productId}`}
            ref={productTileRef}
            portraitCardAspectRatio={
              isPortraitCard(masterVariant.key, isLandscape)
                ? imageDimensions.width / imageDimensions.height
                : /* istanbul ignore next */
                  undefined
            }
            favourite={{
              isSelected: isFavourited,
              onSelect: () =>
                handleFavourite(product, !!isFavourited, productIndex),
            }}
            ourPick={showOurPick && productIndex === 0}
            sponsored={product.isSponsored}
            label={label}
            variant={variant}
          />
        </Box>
      )}
      {!isCard && (
        <NonCardProductTile
          productId={productId}
          href={productDetailsURL}
          title={title}
          price={formattedPrice}
          fullPrice={formattedFullPrice}
          discountedPercentage={discountedPercentage}
          image={{
            src: `${masterImage.url}${IMAGE_URL_SUFFIX}`,
            alt: title,
          }}
          rating={rating || undefined}
          titleAs="h3"
          priceAs="p"
          onClick={handleClick}
          key={`product-${productId}`}
          ref={productTileRef}
          ourPick={showOurPick && productIndex === 0}
          sponsored={product.isSponsored}
          favourite={{
            isSelected: isFavourited,
            onSelect: () =>
              handleFavourite(product, !!isFavourited, productIndex),
          }}
          pill={
            primaryProductPill ? transformPill(primaryProductPill) : undefined
          }
          // eslint-disable-next-line react/no-unstable-nested-components
          linkAs={({ children, ...rest }) => (
            <CtaButton
              {...rest}
              onClick={async (e: MouseEvent) => {
                e.preventDefault()
                return ctaClick()
              }}
            >
              {renderCtaText(product.category.department, children)}
            </CtaButton>
          )}
        />
      )}
    </>
  )
}

ProductTile.displayName = 'ProductTile'
