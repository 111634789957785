import {
  Box,
  Link,
  Image,
  focusIndicatorStyles,
} from '@moonpig/launchpad-components'
import React, { forwardRef } from 'react'
import { styled, breakpointUp } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { PillVariant } from '../../../types'
import { FavouriteButton } from './components/FavouriteButton'
import { createPill } from './createPill'

export type CardFormat = 'landscape' | 'portrait' | 'square' | 'postcard'

const StyledCard = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0 auto;
  transition: transform 200ms ease-in-out;
  will-change: transform;
  align-items: center;
`

const StyledLink = styled(Link)`
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  max-width: 100%;
  height: 100%;
  ${focusIndicatorStyles}
  transition: background-color 200ms ease-in-out;

  ${s({
    bgcolor: 'colorBlack05',
    px: { xs: '10px', sm: 8, lg: 11 },
    py: { xs: 10, md: 12 },
    pt: { xs: '50px', md: '56px' },
    borderRadius: 2,
  })};

  ${breakpointUp('lg')} {
    &:hover:not(:has(span:hover)) {
      ${s({
        bgcolor: 'colorBlack10',
      })};

      ${StyledCard} {
        transform: scale(1.05);
      }
    }
  }
`

const StyledBackPage = styled(Box)<{ format: CardFormat }>`
  position: absolute;
  width: 100%;
  z-index: 1;
  flex: 1;
  transform: ${({ format }) => {
    switch (format) {
      case 'portrait':
      case 'square':
        return 'rotateY(-20deg) skewY(-5deg)'
      case 'landscape':
        return 'rotateX(-10deg) skewX(5deg)'
      default:
        return 'none'
    }
  }};
  transform-origin: ${({ format }) =>
    format === 'landscape' ? 'top left' : 'center left'};

  ${s({
    bgcolor: 'colorBlack00',
    boxShadow: 2,
  })};
`

const StyledFrontPage = styled.div`
  position: relative;
  flex: 1;
  z-index: 2;
  transform: translateZ(100px);

  ${s({
    boxShadow: 3,
  })};
`

const StyledImage = styled(Image)`
  width: 100%;
  display: block;
`

type Image = {
  src: string
  alt: string
  dimensions: { width: number; height: number }
}

type Props = {
  /** Card images to display */
  images: {
    front: Image
    insideRight?: Image
  }
  /** Card format */
  format: CardFormat
  /** Card product link */
  href: string
  /** Flag to determine if the card is sponsored */
  isSponsored: boolean
  /** Callback when the card is clicked */
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  /** Pill to display on the card */
  pill?: {
    label: string
    variant: PillVariant
  }
  /** Determines the favourite toggle state props */
  favourite?: {
    /** State of the favourite toggle */
    isSelected?: boolean
    /** Callback function to change the state of the favourite toggle */
    onSelect: () => void
  }
}

export const CardProductTileVariant = forwardRef<HTMLAnchorElement, Props>(
  ({ images, format, href, isSponsored, onClick, pill, favourite }, ref) => {
    return (
      <>
        <StyledLink
          href={href}
          data-testid="shared-products-card-product-tile-variant"
          aria-label={images.front.alt}
          onClick={onClick}
          ref={ref}
        >
          {createPill({ isSponsored, pill })}
          <StyledCard>
            <StyledFrontPage>
              <StyledImage src={images.front.src} alt={images.front.alt} />
            </StyledFrontPage>
            <StyledBackPage format={format}>
              {images.insideRight && (
                <StyledImage src={images.insideRight.src} />
              )}
            </StyledBackPage>
          </StyledCard>
          {favourite && <FavouriteButton favourite={favourite} />}
        </StyledLink>
      </>
    )
  },
)
